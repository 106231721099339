import type { LocationCardApiModel } from '@groenhart/api';

export default function useOpeningTime(location: LocationCardApiModel) {
  type Opening = {
    openingTime: Date | undefined;
    closingTime: Date | undefined;
    nextOpeningTime: string | undefined;
    status: 'open' | 'closed' | 'later';
    holiday: boolean;
  };

  const opening = computed<Opening | null>(() => {
    const now = new Date();
    const openingTime = getDateObjFromTimeString(location.openTodayFrom ?? '');
    const closingTime = getDateObjFromTimeString(location.openTodayUntil ?? '');
    const nextOpeningTime = location.nextOpeningTime ?? '';
    const holiday = false;

    let status: Opening['status'] = 'closed';

    if (!openingTime || !closingTime) {
      return {
        openingTime,
        closingTime,
        nextOpeningTime,
        status,
        holiday,
      };
    }

    if (now >= openingTime && now <= closingTime) {
      status = 'open';
    }

    if (now < openingTime) {
      status = 'later';
    }

    if (now > closingTime) {
      status = 'closed';
    }

    return {
      openingTime,
      closingTime,
      nextOpeningTime,
      status,
      holiday,
    };
  });

  const text = computed(() => {
    if (opening.value?.status === 'open') {
      return `Vandaag geopend tot ${formatTime(location.openTodayUntil ?? '')}`;
    }

    if (opening.value?.status === 'later') {
      return `Opent om ${formatTime(location.openTodayFrom ?? '')}`;
    }

    if (opening.value?.status === 'closed') {
      return location.nextOpeningTime;
    }
  });

  function getDateObjFromTimeString(time: string): Date | undefined {
    if (!time) {
      return;
    }

    const splitTime = time.split(':');
    const date = new Date();
    date.setHours(Number(splitTime[0]));
    date.setMinutes(Number(splitTime[1]));
    date.setSeconds(Number(splitTime[2]));

    return date;
  }

  function formatTime(time: string): string {
    if (!time) {
      return '';
    }

    const splitTime = time.split(':');
    return `${splitTime[0]}:${splitTime[1]}`;
  }

  return {
    opening,
    text,
  };
}
